import { graphql, useStaticQuery } from 'gatsby';
import React from 'react'
import HtmlHead from '../html-head'

const LegalHead: React.FC = () => {
    const querymetaData = useStaticQuery(graphql`
    query legalQuery {
            allContentfulPageSeoData(filter: { pagePath: { eq: "/website-privacy-policy" } }) {
              edges {
                  node {
                      title
                      canonicalLink
                      metaDescription {
                          metaDescription
                      }
                      openGraphImage {
                          file {
                              url
                          }
                      }
                  }
              }
          }
        }
`);

    const metaData = querymetaData.allContentfulPageSeoData.edges[0].node;

    return (
        <>
            <HtmlHead
                title={metaData.title}
                metaDescription={metaData.metaDescription.metaDescription}
                openGraphImage={metaData.openGraphImage.file.url}
                canonicalLink={metaData.canonicalLink}
            />
        </>
    )
}

export default LegalHead;