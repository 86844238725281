import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer/dist/types';

import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import './legal.css';
import HtmlHead from '../../components/html-head';
import { graphql } from 'gatsby';
import LegalHead from '../../components/legal-head';

type Legal = {
    pageContext: {
        pageTitle: string;
        content: string;
    };
};

const Legal: React.FC<Legal> = data => {
    const richTextContent: Document = JSON.parse(data.pageContext.content);
    const title = data.pageContext.pageTitle;
    const options: Options = {
        renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br key={`br-${i}`} />, text])
    };
    const richText = documentToReactComponents(richTextContent, options);

    return (
        <>
            <LegalHead />
            <Navbar />
            <section className="legal-section">
                <div className="container" style={{ maxWidth: '830px' }}>
                    <h1 className="text-3xl semi-bold text-align-center">{title}</h1>
                    <br />
                    <br />
                    <br />
                    {richText}
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Legal;


